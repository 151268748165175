import React from "react";
import { Redirect } from "react-router-dom";
import loadable from "@loadable/component";
import Dashboard from '../pages/Dashboard/index';
import MemberDashboard from '../pages/Member/MemberDashboard';
import CustomerDashboard from '../pages/Customers/Dashboard';

// ---------- admin components ------------- //
const Login = loadable(() => import('../pages/Authentication/Login'));
const Logout = loadable(() => import('../pages/Authentication/Logout'));
const Forgot = loadable(() => import('../pages/Authentication/ForgetPassword'));
const Report = loadable(() => import('../pages/Dashboard/Report/Report'));
const JobList = loadable(() => import('../pages/Jobs/JobList'));
const Job = loadable(() => import('../pages/Jobs/Job'));
const MList = loadable(() => import('../pages/Jobs/MList'));
const CustomerList = loadable(() => import('../pages/Customers/CustomerList'));
const ContactList = loadable(() => import('../pages/Customers/ContactList'));
const MemberList = loadable(() => import('../pages/Member/List'));
const Customer = loadable(() => import('../pages/Customers/Customer'));
const Office = loadable(() => import('../pages/Customers/Office'));
const Page = loadable(() => import('../pages/Site/Page'));
const AccountForAdmin = loadable(() => import('../pages/Common/AccountForAdmin'));

// --------- member components ----------- //
const MemberJobList  = loadable(() => import('../pages/Member/MemberJobList'));
const MemberAppliedJobList  = loadable(() => import('../pages/Member/MemberAppliedJobList'));
const MemberAnfragenList  = loadable(() => import('../pages/Member/MemberAnfragenList'));
const MemberJobAgent  = loadable(() => import('../pages/Member/MemberJobAgent'));
const MemberResume  = loadable(() => import('../pages/Member/MemberResume'));
const MemberAccount  = loadable(() => import('../pages/Member/AccountForAdmin'));

// --------- customer components ----------- //
const CustomerJobList = loadable(() => import('../pages/Customers/JobList'));
const CustomerAccountPage = loadable(() => import('../pages/Customers/CustomerAccountPage'));
const PersonelAgent = loadable(() => import('../pages/Customers/PersonelAgent'));
const PersonelAgentSettings = loadable(() => import('../pages/Customers/PersonelAgentSettings'));

// --------- common pages components ----------- //
const AccountPage  = loadable(() => import('../pages/Common/Account'));
const CandidateList  = loadable(() => import('../pages/Candidates/CandidateList'));
const CandidateDetail  = loadable(() => import('../pages/Candidates/CandidateDetail'));
const CandidateSearch  = loadable(() => import('../pages/Candidates/CandidateSearch'));
const CandidateMeets  = loadable(() => import('../pages/Candidates/CandidateMeets'));
const CandidateMeetsForAdmin  = loadable(() => import('../pages/Candidates/CandidateMeetsForAdmin'));
const UserList = loadable(() => import('../pages/Common/UserList'));
const UserAccount = loadable(() => import('../pages/Common/UserAccount'));
const UserCreate = loadable(() => import('../pages/Common/UserCreate'));
const Profile = loadable(() => import('../pages/Common/Profile/contacts-profile'));
const ProfileSummary = loadable(() => import('../pages/Common/Profile/contacts-profile-summary'));

const authProtectedRoutesForCandidates = [
    { path: "/dashboard", component: MemberDashboard },
    { path: "/jobs", component: MemberJobList },
    { path: "/applied-jobs", component: MemberAppliedJobList },
    { path: "/matched-anfragen", component: MemberAnfragenList },
    { path: "/job-agent", component: MemberJobAgent },
    { path: "/resume", component: MemberResume },
    { path: "/account", component: AccountPage },
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

// admin
const authProtectedRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/report/:customer_uuid", component: Report },
    { path: "/customers", component: CustomerList },
    { path: "/contacts", component: ContactList },
    { path: "/members", component: MemberList },
    { path: "/members/:member_uuid", component: MemberAccount },
    { path: "/members/resume/:member_uuid/:application_id", component: MemberResume },
    { path: "/candidates", component: CandidateList },
    { path: "/candidates/meets", component: CandidateMeetsForAdmin },
    { path: "/candidates/:id/:job_uuid", component: CandidateDetail },
    { path: "/customer/:uuid/office/:office_id", component: Office },
    { path: "/customer/:action/:uuid?", component: Customer },
    { path: "/jobs/:action", component: Job },
    { path: "/jobs/office/:member_uuid/:office_id", component: JobList },
    { path: "/jobs/:member_uuid/:office_id", component: JobList },
    { path: "/jobs/:action/:member_uuid/:office_id", component: Job },
    { path: "/jobs", component: JobList },
    { path: "/job/candidates/:member_uuid?/:job_uuid?", component: CandidateList },
    { path: "/job/:action/:uuid", component: Job },
    { path: "/management/:list", component: MList },
    { path: "/site/page/:page", component: Page },
    { path: "/account", component: AccountForAdmin },
    { path: "/account/users", component: UserList },
    { path: "/account/users/create", component: UserCreate },
    { path: "/account/users/:user_id", component: UserAccount },
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

// customer
const authProtectedRoutesForMembers = [
    { path: "/dashboard", component: CustomerDashboard },
    { path: "/report/:customer_uuid", component: Report },
    { path: "/customers/:member_uuid", component: ContactList },
    { path: "/customer/:uuid/office/:office_id", component: Office },
    { path: "/customer/:action/:uuid?", component: Customer },
    { path: "/agent/:customer_uuid", component: PersonelAgent },
    { path: "/agent/settings/:customer_uuid", component: PersonelAgentSettings },
    { path: "/candidates/:member_uuid", component: CandidateList },
    { path: "/candidates/search/:customer_uuid", component: CandidateSearch },
    { path: "/candidates/meets/:customer_uuid", component: CandidateMeets },
    { path: "/jobs/office/:member_uuid/:office_id", component: CustomerJobList },
    { path: "/jobs/:action/:member_uuid/:office_id?", component: Job},
    { path: "/jobs/:member_uuid", component: CustomerJobList },
    { path: "/job/candidates/:member_uuid/:job_uuid", component: CandidateList },
    { path: "/job/:action/:uuid", component: Job },
    { path: "/candidates/:id/:job_uuid", component: CandidateDetail },
    { path: "/profile/:member_uuid/:application_id", component: Profile },
    { path: "/profile_summary/:member_uuid/:customer_uuid", component: ProfileSummary },
    { path: "/account/users", component: UserList },
    { path: "/account/users/create", component: UserCreate },
    { path: "/account/users/:user_id", component: UserAccount },
    { path: "/account/:customer_uuid", component: CustomerAccountPage },
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: Forgot },
  { path: "/", exact: true, component: Login }
]

export { authProtectedRoutes, authProtectedRoutesForMembers, authProtectedRoutesForCandidates, publicRoutes }
