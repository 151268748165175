import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import avatar from "../../../assets/images/avatar.png"
import { role } from "../../../helpers/utils";
import { version } from "../../../Const"

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false)
  const [userObj, setUserObj] = useState({profileImage: null, fullname: "", email: "", last_login: ""});

  useEffect(() => {
    let data = JSON.parse(sessionStorage.getItem('authUser'));
    if(data){
      setUserObj({
        profileImage: data.user.profile_image,
        fullname: role() === "candidate" ? data.user.first_name + " " + data.user.last_name : data.user.fullname,
        email: data.user.email,
        last_login: data.user.last_login,
        member_uuid: data.user.member_uuid ? data.user.member_uuid : null
      })
    }
  }, [props.success])

  const prepareLogout = () => {
    document.cookie = 'lmuuid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    props.history.push("/logout");
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            style={{objectFit: 'cover'}}
            src={userObj.profileImage === null ? avatar : userObj.profileImage}
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{userObj.fullname}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a">
            {" "}
            <i className="bx bx-package font-size-16 align-middle me-1" />
            Version {version}
          </DropdownItem>

          <DropdownItem tag="a" onClick={() => props.history.push(`${ (role() === "candidate" || role() === "admin") ? '/account' : '/account/' + userObj.member_uuid}`)}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Account")}{" "}
          </DropdownItem>

          {role() !== "candidate" &&
            <DropdownItem tag="a" onClick={() => props.history.push('/account/users')}>
              {" "}
              <i className="bx bx-folder font-size-16 align-middle me-1" />
              {props.t("Users")}{" "}
            </DropdownItem>
          }

          {role() === "candidate" &&
            <DropdownItem tag="a" onClick={() => props.history.push('/job-agent')}>
              {" "}
              <i className="bx bx-bell font-size-16 align-middle me-1" />
              {props.t("Job Agent")}{" "}
            </DropdownItem>
          }

          <div className="dropdown-divider"/>
          <Link to="#" onClick={() => prepareLogout()} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  history: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
