export const version = "2.9.4"
export const domain = "stellenangebote-psychiatrie.de"
export const brand = "Stellenangebote Psychatrie"
// in development
//export const web = "http://localhost:8888/"
//export const api_gateway = "http://localhost:8888/api/v1"

// in production
export const web = "https://beta.stellenangebote-psychiatrie.de/"
export const api_gateway = "https://beta.stellenangebote-psychiatrie.de/api/v1"
