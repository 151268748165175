import PropTypes from 'prop-types'
import React from "react"
import { Switch, BrowserRouter as Router } from "react-router-dom"
import Authmiddleware from "./routes/route"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import { role } from "./helpers/utils"
import { ToastContainer } from 'react-toastify'
import { authProtectedRoutes, authProtectedRoutesForCandidates, authProtectedRoutesForMembers, publicRoutes } from "./routes"

const App = () => {
  return (
    <>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <>
        <Router>
          <Switch>

            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {role() === "admin" && authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={HorizontalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}

            {role() === "member" && authProtectedRoutesForMembers.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={HorizontalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}

            {role() === "candidate" &&  authProtectedRoutesForCandidates.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={HorizontalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}

          </Switch>
        </Router>
      </>
    </>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

export default App
