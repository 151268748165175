import React from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import MetaTags from "react-meta-tags"
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import CardUser from "./card-user"
import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"
import Earning from "./earning"
import SalesAnalytics from "./sales-analytics"
import { getDashboardData, postDashboard, getDashboards } from "../../helpers/service_helper"
import JobRow from "./JobRow";
import moment from "moment";
import { Link } from "react-router-dom";
import { candidateApplicationStatus, meetStatus, df } from "../../helpers/utils";
import PropTypes from "prop-types";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      reports: [],
      dashboards: null,
      dashboard_id: null,
      dashboard_title: null
    }
  }

  componentDidMount = async() => {
    this.loadDashboardList();
    this.loadDashboard()
  }

  loadDashboardList = async () => {
    const dashboards = await getDashboards();
    this.setState({dashboards});
  }

  loadDashboard = async () => {
    const db_data = sessionStorage.getItem('dashboard');
    let tempData = null;

    if(db_data !== undefined && db_data !== "undefined"){
      tempData = JSON.parse(db_data);
    }

    if(tempData){
      this.setState({
        data: tempData,
        reports: [
          {
            icon: "bx bx-briefcase",
            title: this.props.t("Jobs"),
            value: tempData.counts.all_jobs,
            badgeValue: "100%",
            color: "primary",
            desc: this.props.t("Total jobs"),
          },
          {
            icon: "bx bx-globe",
            title: this.props.t("Published"),
            value: tempData.counts.published_jobs,
            badgeValue: (100 - ((parseInt(tempData.counts.published_jobs) * 100) / parseInt(tempData.counts.all_jobs))).toFixed(2) + "%",
            color: "success",
            desc: this.props.t("ratio"),
          },
          {
            icon: "bx bx-user",
            title: this.props.t("Applied"),
            value: tempData.counts.applies,
            badgeValue: ((parseInt(tempData.counts.applies) * 100) / parseInt(tempData.counts.published_jobs)).toFixed(2) + "%",
            color: "warning",
            desc: this.props.t("ratio"),
          }
        ]
      })
    }

    const data = await getDashboardData(this.state.dashboard_id);

    sessionStorage.setItem('dashboard', JSON.stringify(data));

    this.setState({
      data,
      reports: [
        {
          icon: "bx bx-briefcase",
          title: this.props.t("Jobs"),
          value: data.counts.all_jobs,
          badgeValue: "100%",
          color: "primary",
          desc: this.props.t("Total jobs"),
        },
        {
          icon: "bx bx-globe",
          title: this.props.t("Published"),
          value: data.counts.published_jobs,
          badgeValue: (100 - ((parseInt(data.counts.published_jobs) * 100) / parseInt(data.counts.all_jobs))).toFixed(2) + "%",
          color: "success",
          desc: this.props.t("ratio"),
        },
        {
          icon: "bx bx-user",
          title: this.props.t("Applied"),
          value: data.counts.applies,
          badgeValue: ((parseInt(data.counts.applies) * 100) / parseInt(data.counts.published_jobs)).toFixed(2) + "%",
          color: "warning",
          desc: this.props.t("ratio"),
        }
      ]
    });
  }

  handleSaveDashboard = async() => {
    await postDashboard(this.state.data);
    await this.loadDashboardList();
  }

  handleSelectDashboard = async (dashboard_id, dashboard_title) => {
    this.setState({dashboard_id, dashboard_title}, () => this.loadDashboard());
  }

  render(){
    return (
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>{this.props.t("Dashboard")}</title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title={this.props.t("Dashboard")} breadcrumbItem={`${!this.state.dashboard_title ? this.props.t("Analytics") : this.state.dashboard_title}`} />
              {this.state.data &&
                <CardUser dashboards={this.state.dashboards} onSelectDashboard={this.handleSelectDashboard} onSave={this.handleSaveDashboard} reports={this.state.data}/>
              }
              <Row>
                <CardWelcome customersCount={this.state.data ? this.state.data.customers_activated : 0} membersCount={this.state.data ? this.state.data.members_activated : 0}/>
                <Col xl="8">
                  <Row>
                    <MiniWidget reports={this.state.reports} />
                  </Row>
                </Col>
              </Row>
              <Row>
                {this.state.data &&
                    <>
                      <Earning reports={this.state.data}/>
                      <SalesAnalytics totalJobs={this.state.data.counts.all_jobs} customerJobs={this.state.data.customer_jobs} />
                    </>
                }
              </Row>
              <Row>
                <Col lg="8">
                  <Card>
                    <CardBody>
                      <div className="h4 card-title">{this.props.t("Current Meet Requests")}</div>
                      <p className="card-title-desc">
                        {this.props.t("You can find the information about latest meet requests.")}
                      </p>
                      <table className="table">
                        <thead className="table-light">
                        <tr>
                          <th width={30}>#</th>
                          <th>{this.props.t("Employer")}</th>
                          <th>{this.props.t("Member")}</th>
                          <th width={180}>{this.props.t("Send Date")}</th>
                          <th width={180}>{this.props.t("Action Date")}</th>
                          <th width={100}>{this.props.t("Status")}</th>
                        </tr>
                        </thead>
                        <tbody className="table-hover table-sm">
                        {this.state.data && this.state.data.meet_list.map((mr, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{mr.title}</td>
                              <td>{mr.full_name}</td>
                              <td>{df(moment(mr.send_date), 'DD.MM.YYYY HH:mm', 'bx bx-calendar')}</td>
                              <td>{df(moment(mr.action_date), 'DD.MM.YYYY HH:mm', 'bx bx-calendar-check')}</td>
                              <td>{meetStatus(mr.action)}</td>
                            </tr>
                          )
                        })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <div className="h4 card-title">{this.props.t("Latest Applies")}</div>
                      <p className="card-title-desc">
                        {this.props.t("The latest 5 candidates who applied to which customer.")}
                      </p>
                      <table className="table">
                        <thead className="table-light">
                        <tr>
                          <th width={30}>#</th>
                          <th>{this.props.t("Full Name")}</th>
                          <th>{this.props.t("Employer")}</th>
                          <th width={160}>{this.props.t("Applied Date")}</th>
                          <th width={300}>{this.props.t("Job Group")}</th>
                          <th width={100}>{this.props.t("Status")}</th>
                        </tr>
                        </thead>
                        <tbody className="table-hover table-sm">
                        {this.state.data && this.state.data.candidates && this.state.data.candidates.map((candidate, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{candidate.fullname}</td>
                              <td>{candidate.customer_title}</td>
                              <td>{df(moment(candidate.application_date),'DD.MM.YYYY HH:mm','bx bx-calendar-check')}</td>
                              <td><Link to={`/job/candidates/${candidate.customer_uuid}/${candidate.job_uuid}`}>{candidate.job_title}</Link></td>
                              <td>{candidateApplicationStatus(candidate.delivery_status)}</td>
                            </tr>
                          )
                        })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card>
                    <CardBody>
                      <div className="h4 card-title">{this.props.t("Most Viewed Jobs")}</div>
                      <p className="card-title-desc mb-0">{this.props.t("The most viewed jobs in everytime with view counts.")}</p>
                      <div className="table-responsive">
                        <table className="table mb-0">
                          <tbody>
                          {this.state.data && this.state.data.most.map((j, k) => {
                            return(
                              <JobRow key={k} job={j}/>
                            )
                          })}
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
    )
  }
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)
