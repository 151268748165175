import React from "react";
import PropTypes from "prop-types"
import { web } from "../../Const";

const JobRow = (props)  => {
    return(
        <tr>
            <td>
                <small>#{props.job.id}</small>
                {props.job && <br/>}
                {props.job.job && <><small><b>{props.job.job}</b></small><br/></>}
                <a href={`${web}/jobs/${props.job.title.replace(/[^a-z0-9]/gi, '_').toLowerCase()}-${props.job.id}.html`} target="_blank" rel="noreferrer">{props.job.title}</a>
                <br/>
                <small>{props.job.branch && props.job.branch + " "}<i className="bx bx-map"/> {props.job.usage_site_cities} &nbsp; <i className="bx bx-building"/> {props.job.address_name}</small>
            </td>
        </tr>
    )
}

JobRow.propTypes = {
    job: PropTypes.any
}


export default JobRow;
