import React from "react"
import PropTypes from "prop-types"
import { Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"

const SalesAnalytics = props => {
  const series = [...props.customerJobs.map(c => parseInt(c.count) )]
  const options = {
    labels: [...props.customerJobs.map(c => c.title)],
    legend: { show: true, position: "bottom" },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
      },
    },
  }

  return (
    <React.Fragment>
      <Col xl="4">
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Jobs nach Kunden</h4>
            <div>
              <div id="donut-chart">
                <ReactApexChart
                  options={options}
                  series={series}
                  type="polarArea"
                  height={352}
                  className="apex-charts"
                />
              </div>
            </div>

          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

SalesAnalytics.propTypes = {
  totalJobs: PropTypes.any,
  customerJobs: PropTypes.array
}


export default SalesAnalytics
