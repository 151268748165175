import PropTypes from 'prop-types';
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { withTranslation } from "react-i18next";
import { getNotifications, clearNotifications } from "../../../helpers/service_helper";
import moment from "moment";
import 'moment/locale/de';
moment.locale('de')

class NotificationDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
      user_id: null,
      notifications: [],
      badge_count: 0
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }

  async componentDidMount() {
    let user_data = JSON.parse(sessionStorage.getItem('authUser'));
    let user_id = user_data.user.id ? user_data.user.id : null;
    let notifications = await getNotifications();

    if (notifications){
      let badge_count = 0;

      notifications.forEach((item) => {
        if (item['status'] === "0"){
          badge_count = badge_count + 1
        }
      })

      this.setState({user_id, notifications, badge_count})
    }
  }

  async mark_all_read(){
    await clearNotifications()
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="dropdown d-inline-block"
          tag="li">
          <DropdownToggle
            className="btn header-item noti-icon"
            tag="button"
            id="page-header-notifications-dropdown">
            <i className="bx bx-bell bx-tada" />
            <span className="badge bg-danger rounded-pill">{this.state.badge_count}</span>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0">Benachrichtigungen</h6>
                </Col>
                <div className="col-auto">
                  <a href="#" className="small" onClick={this.mark_all_read}>
                    {" "}
                    Alles als gelesen markieren
                  </a>
                </div>
              </Row>
            </div>
            <SimpleBar style={{ height: "230px" }}>
              {this.state.notifications.map((n, i) => {
                return (
                    <Link to="#" className="text-reset notification-item" key={i}>
                      <div className="media">
                        <div className="avatar-xs me-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          <i className={`bx bx-${n.notification_type}`} />
                        </span>
                        </div>
                        <div className="media-body">
                          <h6 className="mt-0 mb-1">
                            Neue Nachricht
                          </h6>
                          <div className="font-size-12 text-muted">
                            <p className="mb-1">
                              {n.content}
                            </p>
                            <p className="mb-0">
                              <i className="mdi mdi-clock-outline" />
                              {" "}{moment(n.created_at, 'YYYY-MM-DD', 'de').fromNow()}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                )
              })}
            </SimpleBar>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    )
  }
}

NotificationDropdown.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(NotificationDropdown)
