import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { withTranslation } from "react-i18next"
import { Row, Col, Card, CardBody, Media, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap"
import avatar from "../../assets/images/avatar.png"

function CardUser(props) {
  const [settingsMenu, setSettingsMenu] = useState(false)
  const [userObj, setUserObj] = useState({profileImage: null, fullname: "", email: "", last_login: ""});
  const toggleSettings = () => {setSettingsMenu(!settingsMenu)}

  useEffect(() => {
    let data = JSON.parse(sessionStorage.getItem('authUser'));
    if(data){
      setUserObj({
        profileImage: data.user.profile_image,
        fullname: data.user.fullname,
        email: data.user.email,
        last_login: data.user.last_login
      })
    }
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="4">
                  <Media>
                    <div className="me-3">
                      <img src={userObj.profileImage === null ? avatar : userObj.profileImage } className="avatar-md rounded-circle img-thumbnail" />
                    </div>
                    <Media className="align-self-center" body>
                      <div className="text-muted">
                        <p className="mb-2"><small>{moment(userObj.last_login).format("DD.MM.YYYY HH:mm")}</small></p>
                        <h5 className="mb-1">{userObj.fullname}</h5>
                        <p className="mb-0">{userObj.email}</p>
                      </div>
                    </Media>
                  </Media>
                </Col>
                <Col lg="4" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            {props.t("Customer")}
                          </p>
                          <h5 className="mb-0">{props.reports.customers_activated}</h5>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            {props.t("Pending")}
                          </p>
                          <h5 className="mb-0">{props.reports.customers_pending}</h5>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            {props.t("Members")}
                          </p>
                          <h5 className="mb-0">{props.reports.members_activated}</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg="4" className="d-none d-lg-block">
                  <div className="clearfix mt-4 mt-lg-0">
                    <div className="float-end">
                      <div><p className="text-muted text-truncate mb-2">{" "}</p></div>
                      <Dropdown
                        isOpen={settingsMenu}
                        toggle={toggleSettings}
                        className="float-end">
                        <DropdownToggle tag="button" className="btn btn-primary">
                          <i className="bx bx-pie-chart align-middle me-1" /> {props.t("Dashboards")}
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem onClick={() => props.onSelectDashboard(null, null)}>{props.t("Current")}</DropdownItem>
                          {props.dashboards && props.dashboards.map((d,i) => {
                            return <DropdownItem key={i} onClick={() => props.onSelectDashboard(d._source.id, d._source.name)}>{d._source.name}</DropdownItem>
                          })}
                          <DropdownItem divider />
                          <DropdownItem onClick={() => props.onSave()}>{props.t("Save")} {props.t("Dashboard")}</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

CardUser.propTypes = {
  reports: PropTypes.any,
  onSave: PropTypes.func,
  onSelectDashboard: PropTypes.func,
  dashboards: PropTypes.any,
  t: PropTypes.any
}

export default withTranslation()(CardUser)
