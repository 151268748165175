import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"


const getLoggedInUser = () => {
    const user = sessionStorage.getItem("user")
    if (user) return JSON.parse(user)
    return null
}

const isUserAuthenticated = () => {
    return getLoggedInUser() !== null
}

const postJwtLogin = data => post(url.POST_JWT_LOGIN, data)

const postForget = data => post(url.POST_FORGET, data)

const getDashboardData = (dashboard_id = null) => {
    let path = url.GET_DASHBOARD;

    if(dashboard_id){
        path = url.GET_DASHBOARD + "/" + dashboard_id;
    }

    return get(path)
}

const getDashboards = () => get(url.GET_DASHBOARDS);

const postDashboard = (data) => post(url.POST_DASHBOARD, data)

const getJobList = (office_id = null, params = []) => {

    let endPoint = url.GET_JOB_LIST;

    if(office_id !== null && office_id !== undefined){
        endPoint = endPoint + "/" + office_id
    }

    if(params.length > 0){
        endPoint = endPoint + "?"

        params.forEach((obj, i) => {
            endPoint = endPoint + obj.key + "=" + obj.value + "&"
        });
    }

    return get(endPoint)
}

const getJobDetail = (uuid) => {
    return get(url.GET_JOB_DETAIL + "/" + uuid)
}

const createJobDummy = (uuid, office_id = null) => {
    if(office_id !== null && office_id !== undefined){
        return get(url.GET_JOB_CREATE + "/" + uuid + "/" + office_id)
    }else{
        return get(url.GET_JOB_CREATE + "/" + uuid)
    }
}

const createJobEmpty = () => {
    return get(url.GET_JOB_CREATE)
}

const deleteAddress = id => {
    return del(url.DELETE_ADDRESS + "/" + id)
}

const getRegions = () => {
    return get(url.GET_REGIONS)
}

const getRegionsWithCountry = (country) => {
    return post(url.POST_REGIONS, {"country": country})
}


const getCountries = () => {
    return get(url.GET_COUNTRIES)
}

const getLocalities = (region) => {
    return get(url.GET_LOCALITIES + "/" + region)
}

const searchLocalities = (city) => {
    return post(url.POST_SEARCH_CITY, {"city": city})
}

const postAddress = (formData) => {
    return post(url.POST_ADDRESS, formData)
}

const updateJob = (data) => {
    return put(url.PUT_JOB, data)
}

const createJob = (data) => {
    return post(url.PUT_JOB, data)
}

const postBenefit = (data) => {
    return post(url.POST_BENEFIT, data)
}

const deleteJob = (uuid) => {
    return del(url.DELETE_JOB + "/" + uuid)
}

const publishJob = (job_uuid, member_uuid) => {
    return get(url.GET_PUBLISH_JOB + "/" + job_uuid + "/" + member_uuid)
}

const unpublishJob = (job_uuid) => {
    return get(url.GET_UNPUBLISH_JOB + "/" + job_uuid)
}

const getCustomers = (params = []) => {
    let endPoint = url.GET_CUSTOMER_LIST;

    if(params.length > 0){
        endPoint = endPoint + "?"

        params.forEach((obj, i) => {
            endPoint = endPoint + obj.key + "=" + obj.value + "&"
        });
    }

    return get(endPoint)
}

const getOffices = (member_uuid) => {
    return get(url.GET_OFFICES + "/" + member_uuid)
}

const getContacts = (member_uuid) => {
    return get(url.GET_CONTACTS + "/" + member_uuid)
}

const putContacts = (data) => {
    return put(url.PUT_CONTACTS, data)
}

const postCustomer = (data) => {
    return post(url.POST_CUSTOMER, data)
}

const postOffice = (data) => {
    return post(url.POST_OFFICE, data)
}

const getCustomer = (uuid) => {
    return get(url.GET_CUSTOMER + "/" + uuid)
}

const updateCustomer = (uuid, data) => {
    return put(url.PUT_CUSTOMER + "/" + uuid, data);
}

const deleteCustomer = (uuid) => {
    return del(url.DELETE_CUSTOMER + "/" + uuid)
}

const deletePersonalAgentItem = (uuid, id) => {
    return del(url.DELETE_PA_ITEM + "/" + uuid + "/" + id)
}

const addAgentItem = (uuid, data) => {
    return post(url.ADD_PA_ITEM + "/" + uuid, data)
}

const updateAgentItem = (uuid, id, data) => {
    return put(url.PUT_PA_ITEM + "/" + uuid + "/" + id, data)
}

const getOffice = (uuid, id) => {
    return get(url.GET_OFFICE + "/" + uuid + "/" + id)
}

const deleteOffice = (uuid, id) => {
    return del(url.DELETE_OFFICE + "/" + uuid + "/" + id)
}

const runRobot = (uuid, robot_id) => {
    return get(url.GET_ROBOT + "/" + uuid + "/"  + robot_id)
}

const getDoping = (uuid, status) => {
    return get(url.GET_DOPING + "/" + uuid + "/"  + status)
}

const getManagementList = (list) => {
    return get(url.GET_MANAGEMENT + "/" + list)
}

const putManagementList = (list, id, data) => {
    return put(url.PUT_MANAGEMENT + "/" + list + "/" + id, data)
}

const deleteManageListItem = (list, id) => {
    return del(url.DELETE_MANAGEMENT + "/" + list + "/" + id)
}

const postManagementList = (list) => {
    return post(url.POST_MANAGEMENT + "/" + list, {title: ""})
}

const getPage = (page) => {
    return get(url.GET_PAGE + "/" + page)
}

const putPage = (page, data) => {
    return put(url.PUT_PAGE + "/" + page, data)
}

const getMatchedJobs = () => {
    return get(url.GET_MATCHED_JOBS)
}

const getLatLon = (country, city, region, postalCode) => {
    return post(url.POST_GEOLOCATION, {country, city, region, postalCode})
}

const putUserPreferences = (preferences, uuid = null) => {
    const path = uuid === null ? url.PUT_JOB_ALERT_PREFERENCES : url.PUT_JOB_ALERT_PREFERENCES + "/" + uuid;
    return put(path, preferences)
}

const getUserPreferences = (uuid = null) => {
    const path = uuid === null ? url.GET_JOB_ALERT_PREFERENCES : url.GET_JOB_ALERT_PREFERENCES + "/" + uuid;
    return get(path)
}

const postParseResume = (data) => {
    return post(url.POST_PARSE_RESUME, data)
}

const postUserResume = (data) => {
    return post(url.POST_USER_RESUME, data)
}

const getUserResume = (uuid = null, application_id = null) => {
    const endpoint = uuid === null ? url.GET_USER_RESUME : url.GET_USER_RESUME + "/" + uuid + "/" + application_id;
    return get(endpoint);
}

const getResumeSummary = (uuid = null) => {
    return get(url.GET_RESUME_SUMMARY + "/" + uuid);
}

const deleteResumeAttachment = (type, id, file = null) => {
    const endpoint = file === null ? url.DELETE_RESUME_ATTACHMENT + "/" + type + "/" + id : url.DELETE_RESUME_ATTACHMENT + "/" + type + "/" + id + "/" + file;
    return del(endpoint)
}

const putChangePassword = (password) => {
  return put(url.PUT_CHANGE_PASSWORD, password);
}

const deactivateAccount = () => {
    return put(url.PUT_ACCOUNT_DEACTIVATE);
}

const deleteAccount = (uuid = null) => {
    const path = uuid === null ? url.DELETE_ACCOUNT : url.DELETE_ACCOUNT + "/" + uuid;
    return del(path);
}

const getCandidates = (member_uuid, job_uuid = null, params = []) => {
    let endPoint = url.GET_CANDIDATES;

    if(job_uuid !== null && job_uuid !== undefined){
        endPoint  = endPoint + "/" + member_uuid + "/" + job_uuid;
    }else{
        endPoint = endPoint + "/" + member_uuid;
    }

    if(params.length > 0){
        endPoint = endPoint + "?"

        params.forEach((obj, i) => {
            endPoint = endPoint + obj.key + "=" + obj.value + "&"
        });
    }

    return get(endPoint)
}

const getAppliedCandidates = (member_uuid, job_uuid = null, params = []) => {
    let endPoint = url.GET_APPLIED_CANDIDATES;

    if(job_uuid !== null && job_uuid !== undefined){
        endPoint  = endPoint + "/" + member_uuid + "/" + job_uuid;
    }else{
        endPoint = endPoint + "/" + member_uuid;
    }

    return get(endPoint)
}

const getCandidateDetail = (id, job_uuid) => {
    return get(url.GET_CANDIDATE_DETAIL + "/" + id + "/" + job_uuid)
}

const sendMeetRequest = (member_uuid) => {
    return post(url.POST_MEET_REQUEST, {member_uuid})
}

const sendBulkMeetRequest = (data) => {
    return post(url.POST_BULK_MEET_REQUEST, data)
}

const setMeetResponse = (id, action) => {
    return put(url.PUT_MEET_RESPONSE + "/" + id, {action})
}

const getMeetRequests = (member_uuid = null) => {
    const path = member_uuid === null ? url.GET_MEET_REQUESTS : url.GET_MEET_REQUESTS + "/" + member_uuid;
    return get(path)
}

const deleteMeetRequest = (id) => {
  return del(url.DELETE_MEET_REQUEST + "/" + id)
}

const searchResume = (customer_uuid, data) => {
    return post(url.POST_SEARCH + "/" + customer_uuid, data)
}

const filterMembers = (data) => {
    return post(url.POST_FILTER, data)
}

const getMatchedResumes = (customer_uuid) => {
    return get(url.GET_MATCHED_RESUMES + "/" + customer_uuid)
}

const getMe = () => {
    return get(url.GET_ME)
}

const updateMe = (id,  data) => {
    return put(url.PUT_ME + "/" + id,  data)
}

const getMembers = (member_uuid = null, params = []) => {
    let path = member_uuid === null ? url.GET_MEMBERS : url.GET_MEMBERS + "/" + member_uuid;

    if(params.length > 0){
        path = path + "?"

        params.forEach((obj, i) => {
            path = path + obj.key + "=" + obj.value + "&"
        });
    }

    return get(path)
}

const getUsers = (id = null) => {
    const path = id === null ? url.GET_USERS : url.GET_USERS + "/" + id;
    return get(path);
}

const deleteUser = (id) => {
    return del(url.DELETE_USER + "/" + id);
}

const createUser = (data) => {
    return post(url.POST_USER, data);
}

const getReport = (uuid, startDate, endDate) => {
    if(startDate === null || endDate === null){
        return get(url.GET_REPORT + "/" + uuid);
    }else{
        return get(url.GET_REPORT + "/" + uuid + "/" + startDate + "/" + endDate);
    }
}

const setJobCustomer = (data) => {
    return put(url.PUT_JOB_CUSTOMER, data)
}

const getChatMessages = (chat_session) => {
    return get(url.GET_CHAT + "/" + chat_session);
}

const postChatMessage = (cm) => {
    return post(url.POST_CHAT, cm);
}

const getNotifications = () => {
    return get(url.GET_NOTIFICATIONS);
}

const clearNotifications = () => {
    return del(url.DELETE_NOTIFICATIONS);
}

export {
    getLoggedInUser,
    isUserAuthenticated,
    postJwtLogin,
    postForget,
    getDashboardData,
    getDashboards,
    postDashboard,
    getJobList,
    getJobDetail,
    deleteAddress,
    getRegions,
    getRegionsWithCountry,
    getCountries,
    getLocalities,
    postAddress,
    updateJob,
    createJob,
    postBenefit,
    deleteJob,
    publishJob,
    unpublishJob,
    getCustomers,
    getOffices,
    getContacts,
    postCustomer,
    postOffice,
    getOffice,
    deleteOffice,
    getCustomer,
    updateCustomer,
    deleteCustomer,
    runRobot,
    createJobDummy,
    createJobEmpty,
    getDoping,
    getManagementList,
    putManagementList,
    deleteManageListItem,
    postManagementList,
    getPage,
    putPage,
    getMatchedJobs,
    getLatLon,
    putUserPreferences,
    getUserPreferences,
    postParseResume,
    postUserResume,
    getUserResume,
    deleteResumeAttachment,
    putChangePassword,
    deactivateAccount,
    deleteAccount,
    getCandidates,
    getAppliedCandidates,
    getCandidateDetail,
    sendMeetRequest,
    sendBulkMeetRequest,
    setMeetResponse,
    getMeetRequests,
    deleteMeetRequest,
    searchResume,
    filterMembers,
    getMatchedResumes,
    getMe,
    updateMe,
    getMembers,
    getUsers,
    deleteUser,
    createUser,
    getReport,
    setJobCustomer,
    putContacts,
    getResumeSummary,
    deletePersonalAgentItem,
    addAgentItem,
    updateAgentItem,
    searchLocalities,
    getChatMessages,
    postChatMessage,
    getNotifications,
    clearNotifications
}
