import React from "react";
import moment from "moment/moment";
import {createHash} from "crypto-browserify";

export const calculate_age = (birth_date) => {
    return moment().diff(birth_date, 'years').toString()
}

export const calculate_experiences = (experiences) => {
    let all_time = 0;
    for(let exp of experiences){
        let work_time = moment(exp['finish_date']).diff(exp['start_date'], 'months');
        all_time = all_time + work_time;
    }
    if(all_time > 0){
        let years = Math.floor(all_time/12);
        let months = all_time % 12;
        if(months > 0){
            return years.toString() + "." + months.toString();
        }else{
            return years;
        }
    }

    return "";
}

export const truncateText = (text) => {
    return text.substring(0,text.length / 2) + "..."
}

export const jobUrl = (row, action = "edit") => {
    return `/job/${action}/${row.job_uuid}`
}

export const jobTypeText = (jt) => {
    if(jt === "FULL_TIME"){return "Vollzeit"}
    if(jt === "PART_TIME"){return "Teilzeit"}
    if(jt === "FREE"){return "Freie Mitarbeit"}
    if(jt === "AUSHILFE"){return "Ausbildung"}
    if(jt === "PRAKTIKUM"){return "Praktikum"}
}

export const statusText = (status) => {
    if(status === "Created"){return "Gespeichert"}
    if(status === "Published"){return "Veröffentlicht"}
    if(status === "Finished"){return "Abgelaufen"}
}

export const candidateApplicationStatus = (status) => {
    if(status === "PENDING"){return "Ausstehend"} // Pending
    if(status === "SENT"){return "Gesendet"} //Sent
}

export const customerStatusText = (status) => {
    if(status === "t"){return "Aktiviert"}
    if(status === "f"){return "Anstehend"}
}

export const listTitle = (list) => {
    if(list === "jobs"){return "Berufsgruppen"}
    if(list === "branches"){return "Fachrichtungen"}
    if(list === "benefits"){return "Zusatzleistungen"}
}

export const genderTitle = (gender) => {
    if(gender === "FEMALE"){return "Frau"}
    if(gender === "MALE"){return "Herr"}
    if(gender === "OTHER"){return "Divers"}
    if(gender === "NONE"){return "Keine Angabe"}
}

export const disableStatus = (gender) => {
    if(gender === "UNKNOWN"){return "Keine Angabe"}
    if(gender === "HALF"){return "Gleichgestellt"}
    if(gender === "NONE"){return "Nicht gesetzt"}
    if(gender === "YES"){return "Ja"}
    if(gender === "NO"){return "Nein"}
    if(gender === ""){return "Es sind keine Einträge vorhanden bzw. wurden bereits gelöscht"}
}

export const carrierStatus = (gender) => {
    if(gender === "ACTIVE"){return "Aktiv auf Jobsuche"}
    if(gender === "AUSBILDUNG"){return "Derzeit in Ausbildung"}
    if(gender === "PASSIVE"){return "Derzeit nicht auf Jobsuche"}
    if(gender === "OPEN"){return "Habe Job - bin aber offen für Angebote"}
}

export const meetStatus = (action) => {
    if(action === "0"){return "Ausstehend"} // Pending
    if(action === "1"){return "Akzeptiert"} // Accepted
    if(action === "2"){return "Abgelehnt"}  // Rejected
}

export const lookupInObject = (arr, key, value, field = null, with_all = false) => {
    let found = null;
    arr.forEach(function(obj){
        if(obj[key] === value){
            if(field === null){
                found = obj;
            }else{
                found = obj[field]
            }
        }
    })
    if(found == null && with_all === true){
        return "Alle";
    }
    return found
}

export const df = (d,f, i, show_warning = true) => {
    if(d.isValid()){
        return <><i className={i}/> {d.format(f)}</>;
    }else{
        return show_warning ? <div style={{width: '100%', textAlign: 'center', padding: 2, fontSize: 22, color: '#ee9d47'}}><i className="bx bx-time"/></div> : null
    }
}

export const blobToBase64 = blob => {
    const reader = new FileReader();

    reader.readAsDataURL(blob);

    return new Promise(resolve => {
        reader.onloadend = () => {
            return resolve(reader.result);
        };
    });
};

export const role = () => {
    let auth = sessionStorage.getItem('authUser');
    let role = null;

    if (auth !== null && auth !== "undefined") {
        auth = JSON.parse(auth);
        role = auth.user.role;
    }else{
        sessionStorage.removeItem('authUser');
    }

    return role;
}

export const getStorageUser = () => {
    const data = JSON.parse(sessionStorage.getItem('authUser'));
    return data.user;
}

export const setStorageUser = (updates) => {
    const authUser = JSON.parse(sessionStorage.getItem('authUser'));
    const newUser = {token: authUser.token, user: {...authUser.user, ...updates}}

    sessionStorage.setItem('authUser', JSON.stringify(newUser));

    return newUser;
}

export const  generateUUID = () => {
    let d = new Date().getTime();
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;
        if(d > 0){
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export const md5 = (d) => {
    return createHash('md5').update(d).digest('hex')
}


export const getCookie = (name) => {
    let cookie = {};
    document.cookie.split(';').forEach(function(el) {
        let [k,v] = el.split('=');
        cookie[k.trim()] = v;
    })
    return cookie[name];
}

export const academicTitles = [
    "apl.Prof.",
    "apl.Prof.Dr.",
    "apl.Prof.Dr.Dr.",
    "apl.Prof.MD",
    "apl.Prof.Ph.D.",
    "Cand. med.",
    "Doctor Medic",
    "Dott.",
    "Dr.",
    "Dr. med.",
    "Dr. med. univ.",
    "Dr. univ.Szeged",
    "Dr.(VAK Moskau)",
    "Dr.Dr.",
    "Dr.Dr.h.c.",
    "Dr.h.c.",
    "DR.-ING.",
    "Hon.Prof.",
    "Hon.Prof.Dr.",
    "Hon.Prof.Dr.Dr.",
    "Hon.Prof.MD",
    "Hon.Prof.Ph.D.",
    "MD",
    "MUDr.",
    "PD",
    "PD Dr.",
    "PD Dr.Dr.",
    "PD MD",
    "PD Ph.D.",
    "Ph.D.",
    "Ph.D. Dr.",
    "Ph.D.-CN",
    "Priv.-Doz.",
    "Prof.",
    "Prof. Dr.",
    "Prof. Dr. med.",
    "Prof. Ph. D.",
    "TD",
    "Tip Dr. Ege Uni",
    "UnivProf.",
    "UnivProf.Dott.",
    "UnivProf.Dr.",
    "UnivProf.Dr.Dr.",
    "UnivProf.em.Dr.",
    "UnivProf.MD",
    "UnivProf.Ph.D."
]

export const CvEducationObject = {
    id: null,
    title: "",
    organization: "",
    summary: "",
    startDate: null,
    finishDate: null
}

export const CvExperienceObject = {
    id: null,
    title: null,
    organization: "",
    summary: "",
    startDate: null,
    finishDate: null
}

export const CvLanguageObject = {
    id: null,
    title: null,
    reading: "",
    writing: "",
    speaking: ""
}

export const CvDocumentObject = {
    id: null,
    title: null,
    file: null
}
