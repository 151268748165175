import React from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import MetaTags from "react-meta-tags"
import moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import CardUser from "./card-user"
import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"
import Earning from "./earning"
import SalesAnalytics from "./sales-analytics"
import { getDashboardData } from "../../helpers/service_helper"
import { Link } from "react-router-dom";
import { candidateApplicationStatus, df } from "../../helpers/utils"
import PropTypes from "prop-types";

class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: null,
      reports: []
    }
  }

  componentDidMount = async() => {
    const db_data = sessionStorage.getItem('dashboard');
    let tempData = null;

    if(db_data !== undefined && db_data !== "undefined"){
      tempData = JSON.parse(db_data);
    }

    if(tempData){
      this.setState({
        data: tempData,
        reports: [
          {
            icon: "bx bx-briefcase",
            title: this.props.t('Jobs'),
            value: tempData.counts && tempData.counts.all_jobs,
            badgeValue: "0%",
            color: "success",
            desc: this.props.t('Measure')
          },
          {
            icon: "bx bx-pencil",
            title: this.props.t('Created'),
            value: tempData.counts && tempData.counts.created_jobs,
            badgeValue: "0%",
            color: "success",
            desc: this.props.t('Measure')
          },
          {
            icon: "bx bx-globe",
            title: this.props.t('Published'),
            value: tempData.counts && tempData.counts.published_jobs,
            badgeValue: "0%",
            color: "warning",
            desc: this.props.t('Measure')
          }
        ]
      })
    }

    const data = await getDashboardData();

    if(data){
      sessionStorage.setItem('dashboard', JSON.stringify(data));

      this.setState({
        data,
        reports: [
          {
            icon: "bx bx-briefcase",
            title: this.props.t('Jobs'),
            value: data.counts && data.counts.all_jobs,
            badgeValue: "0%",
            color: "success",
            desc: this.props.t('Measure')
          },
          {
            icon: "bx bx-pencil",
            title: this.props.t('Created'),
            value: data.counts && data.counts.created_jobs,
            badgeValue: "0%",
            color: "success",
            desc: this.props.t('Measure')
          },
          {
            icon: "bx bx-globe",
            title: this.props.t('Published'),
            value: data.counts && data.counts.published_jobs,
            badgeValue: "0%",
            color: "warning",
            desc: this.props.t('Measure')
          }
        ]
      });
    }else{
      sessionStorage.removeItem('dashboard');
    }
  }

  render(){
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t('Dashboard')}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title={this.props.t('Dashboard')} breadcrumbItem={this.props.t('Analytics')} />
            {this.state.data &&
            <CardUser reports={this.state.data}/>
            }
            <Row>
              <CardWelcome/>
              <Col xl="8">
                <Row>
                  <MiniWidget reports={this.state.reports} />
                </Row>
              </Col>
            </Row>
            {this.state.data && this.state.data.candidates &&
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">{this.props.t('Candidates')}</h4>
                      <p>{this.props.t('The list contains last 10 applications to your online jobs.')}</p>
                      <table className="table">
                        <thead className="table-light">
                          <tr>
                            <th width={30}>#</th>
                            <th>Full Name</th>
                            <th width={180}>{this.props.t('Applied Date')}</th>
                            <th>Job Title</th>
                            <th width={100}>{this.props.t('Status')}</th>
                            <th>Channel</th>
                            <th width={40}>{this.props.t('Action')}</th>
                          </tr>
                        </thead>
                        <tbody className="table-hover table-sm">
                          {this.state.data.candidates.map((candidate, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{candidate.fullname}</td>
                                <td>{df(moment(candidate.application_date), 'DD.MM.YYYY HH:mm', 'bx bx-calendar-check')}</td>
                                <td><Link to={`/job/candidates/${candidate.customer_uuid}/${candidate.job_uuid}`}>{candidate.job_title}</Link></td>
                                <td>{candidateApplicationStatus(candidate.delivery_status)}</td>
                                <td>{candidate.delivery_channel}</td>
                                <td style={{textAlign: 'right'}}><Link to={`/candidates/${candidate.application_id}/${candidate.job_uuid}`}><i className="bx bx-show"/></Link></td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            }
            <Row>
              {this.state.data &&
              <>
                <Earning reports={this.state.data}/>
                <SalesAnalytics counts={this.state.data.counts}/>
              </>
              }
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)
