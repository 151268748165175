import React from "react"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const confirmAction = (onConfirm, message = null) => {
    const options = {
        title: 'Bestätigung', // Confirmation
        message: message ?? 'Sind Sie sicher, dass Sie löschen möchten?', // Are you sure wat to delete?
        buttons: [
            {
                label: 'Ja',
                className: 'btn btn-danger',
                onClick: () => onConfirm()
            },
            {
                label: 'Abbrechen',
                className: 'btn btn-light',
                onClick: () => console.log("no")
            }
        ],
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
        willUnmount: () => {},
        afterClose: () => {},
        onClickOutside: () => {},
        onKeypressEscape: () => {}
    };

    confirmAlert(options);
}

export const alertAction = (message) => {
    const options = {
        title: 'Fehler',
        message: message,
        buttons: [
            {
                label: 'Ok',
                className: 'btn btn-danger',
            }
        ],
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
        willUnmount: () => {},
        afterClose: () => {},
        onClickOutside: () => {},
        onKeypressEscape: () => {}
    };

    confirmAlert(options);
}
