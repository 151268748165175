import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import moment from "moment"
import { Row, Col, Card, CardBody, Media, Progress } from "reactstrap"
import avatar from "../../assets/images/avatar.png"
import { Link } from "react-router-dom";

function CardUser(props) {
  const [userObj, setUserObj] = useState({profileImage: null, fullname: "", email: "", last_login: ""});

  useEffect(() => {
    let data = JSON.parse(sessionStorage.getItem('authUser'));
    if(data){
      setUserObj({
        profileImage: data.user.profile_image,
        fullname: data.user.first_name + " " + data.user.last_name,
        email: data.user.email,
        last_login: data.user.last_login
      })
    }
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="4">
                  <Media>
                    <div className="me-3">
                      <img
                        src={(userObj.profileImage === null || userObj.profileImage === "") ? avatar : userObj.profileImage}
                        className="avatar-md rounded-circle img-thumbnail"
                        style={{objectFit: 'cover'}}
                      />
                    </div>
                    <Media className="align-self-center" body>
                      <div className="text-muted">
                        <p className="mb-2"><small>{moment(userObj.last_login).format("DD.MM.YYYY HH:mm")}</small></p>
                        <h5 className="mb-1">{userObj.fullname}</h5>
                        <p className="mb-0">{userObj.email}</p>
                      </div>
                    </Media>
                  </Media>
                </Col>

                <Col lg="4" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs="12" style={{textAlign: 'left'}}>
                          <h5>{props.t("Progress")}</h5>
                          <Progress color="primary" className="progress-lg" value={props.reports.cv_percentage >= 100 ? 100 : props.reports.cv_percentage}>{props.reports.cv_percentage >= 100 ? 100 : props.reports.cv_percentage}%</Progress>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col lg="4" style={{textAlign: 'right'}}>
                  <div className="clearfix mt-4 mt-lg-0">
                    <h5>{props.t("Cv")}</h5>
                    <Link to="/resume" className="btn btn-primary">
                      <i className="bx bx-edit align-middle me-1" /> {props.t("Edit")}
                    </Link>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

CardUser.propTypes = {
  reports: PropTypes.any,
  t: Progress.any
}

export default withTranslation()(CardUser)
