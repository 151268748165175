import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import i18n from "../../../i18n";
import { withTranslation } from "react-i18next";
import languages from "../../../common/languages";

class LanguageDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
      selectedLang: "de",
    }
  }

  componentDidMount() {
    const currentLanguage = sessionStorage.getItem("I18N_LANGUAGE")
    this.setState({ selectedLang: currentLanguage })
  }

  changeLanguageAction = lang => {
    //set language as i18n
    i18n.changeLanguage(lang)
    sessionStorage.setItem("I18N_LANGUAGE", lang)
    this.setState({ selectedLang: lang })
  }

  toggle = () => {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block">
          <DropdownToggle className="btn header-item" tag="button">
            <img
              src={languages.filter(l => l.lang === this.state.selectedLang)[0].flag}
              height="16"
              className="me-1"
            />
          </DropdownToggle>
          <DropdownMenu className="language-switch dropdown-menu-end">
            {languages.map( (language, key) => {
             return (
               <DropdownItem
                 key={key}
                 onClick={() => this.changeLanguageAction(language.lang)}
                 className={`notify-item ${this.state.selectedLang === language.lang ? "active" : "none"
                 }`}
               >
                 <img
                   src={language.flag}
                   className="me-1"
                   height="12"
                 />

                 <span className="align-middle">
                  {language.label}
                </span>
               </DropdownItem>
             )
            })}
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    )
  }
}

export default withTranslation()(LanguageDropdown);
