import axios from "axios";
import { toast } from 'react-toastify';
import { api_gateway } from "../Const";

const axiosApi = axios.create({ baseURL: api_gateway })

const toastOptions = {
  position: "bottom-left",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

axiosApi.interceptors.response.use(
  (response) => {
    // 201 - Created (post)
    // 202 - Accepted (put)
    // 204 - No Content (delete)

    if(response.status === 201 || response.status === 202 || response.status === 204){
      if(response.data.message){
        toast.success(response.data.message, toastOptions);
      }else{
        toast.success('Gespeichert', toastOptions);
      }
    }

    return response;
  },
  (error) => {
    toast.error(error.response.data.message, toastOptions);
    return Promise.reject(error)
  }
)

axiosApi.interceptors.request.use(function (config) {
  let data = JSON.parse(sessionStorage.getItem('authUser'));

  if(data){
    config.headers.Authorization = data['token'];
    config.headers['X-Api-Key'] = data['token'];
  }

  return config;
});


export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data).catch((reason) => {
    console.log("Unbekannter Fehler");
  })
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(api_gateway + url, { ...data }, { ...config })
    .then(response => response.data)
    .catch((error) => {
      console.log("Unbekannter Fehler");
    })
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch((error) => {console.log("Unbekannter Fehler");})
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
    .catch((error) => {console.log("Unbekannter Fehler");})
}
