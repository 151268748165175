import React from "react";
import PropTypes from "prop-types"
import moment from "moment";
import { web } from "../../Const";

const JobRow = (props)  => {
    return(
        <tr>
            <td>
                {props.job && <br/>}
                {props.job.job && <><small><b>{props.job.job}</b></small><br/></>}
                <a href={`${web}/jobs/${props.job.title.replace(/[^a-z0-9]/gi, '_').toLowerCase()}-${props.job.id}.html`} target="_blank" rel="noreferrer">{props.job.title}</a>
                <br/>
                <small><i className="bx bx-show"/> {props.job.views_count} &nbsp; <i className="bx bx-calendar"/> {moment(props.job.published_date).format('DD.MM.YYYY HH:mm')}</small>
            </td>
        </tr>
    )
}

JobRow.propTypes = {
    job: PropTypes.any
}


export default JobRow;
