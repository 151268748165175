import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import * as Sentry from "@sentry/react";
import store from "./store"
import 'react-toastify/dist/ReactToastify.css';
import "./assets/scss/theme.scss";
import App from "./App"

Sentry.init({
  dsn: "https://506a2e8b68204e1cb98e0f9c7e1f20e0@o1078859.ingest.sentry.io/6093962",
  disablePerformance: true,
  tracesSampleRate: 0.1,
});

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
