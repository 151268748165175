import { takeEvery, fork, all } from "redux-saga/effects"

import { FORGET_PASSWORD } from "./actionTypes"

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, () => {
   // TODO: forget password
  })
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
