import germany from "../assets/images/flags/germany.jpg"

const languages = [
  {
    label: "Deutsch",
    flag: germany,
    lang: "de"
  }
]

export default languages
