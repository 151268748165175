import React from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { withTranslation } from "react-i18next"
import moment from "moment"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import CardUser from "./card-user"
import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"
import JobRow from "./JobRow";
import { getDashboardData, setMeetResponse, getChatMessages, postChatMessage, getMatchedJobs } from "../../helpers/service_helper"
import { confirmAction } from "../../helpers/dialog_helper";
import PropTypes from "prop-types";
import placeholder from "../../assets/images/placeholder.png"
import ChatBox, { ChatFrame } from 'react-chat-plugin';
import {md5, role} from "../../helpers/utils";


class MemberDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      reports: [],
      messages: [],
      show_chat: false,
      user: null,
      cs: null,
      cs_customer: null
    }
  }

  handleMeet = (id, action) => {
    confirmAction(async() => {
      await setMeetResponse(id, action);
      await this.loadDashboard();
    }, this.props.t("You cannot change your response, are you sure?"))
  }

  componentDidMount = async() => {
    await this.loadDashboard()
  }

  onSendMessage = async(message) => {
    const msg = {
      "from": "candidate",
      "chat_sess": this.state.cs,
      "sender_uuid": this.state.user.id,
      "sender_name": this.state.user.name,
      "sender_image": this.state.user.image,
      "receiver_uuid": this.state.cs_customer,
      "message_type": "text",
      "content": message,
      "status": 0
    }

    if(message.trim() !== "") {
      const new_obj = {
        author: {
          username: this.state.user.name,
          id: this.state.user.id,
          avatarUrl: this.state.user.image,
        },
        text: message,
        timestamp: +new Date(),
        type: 'text',
      };

      this.setState({
        messages: [...this.state.messages, new_obj]
      })

      await postChatMessage(msg);
    }
  }

  openChat = async(chat_session, customer_id) => {
    let chat_messages = await getChatMessages(chat_session);
    this.setState({messages: chat_messages, show_chat: true, cs: chat_session, cs_customer: customer_id});

    const chatCloseButtons = document.querySelectorAll('.react-chat-close-icon');
    chatCloseButtons.forEach(button => {
      button.removeEventListener('click');
      button.addEventListener('click', () => {
        this.setState({show_chat: false})
      });
    });
  }

  loadDashboard = async () => {
    const db_data = sessionStorage.getItem('dashboard_member');

    let tempData = null;

    if(db_data !== undefined && db_data !== "undefined"){
      tempData = JSON.parse(db_data);
    }

    if(tempData){
      this.setState({
        data: tempData,
        reports: [
          {
            icon: "bx bx-briefcase",
            title: this.props.t("Applied"),
            value: tempData.application_count,
            badgeValue: "",
            color: "success",
            desc: this.props.t("Your job applies."),
          },
          {
            icon: "bx bx-eye",
            title: "Stellenangebote",  //this.props.t("Viewed"),
            value: tempData.jobs_count.all_jobs,
            badgeValue: "",
            color: "success",
            desc: "Anzahl der Stellenangebote"  //this.props.t("Your resume views."),
          },
          {
            icon: "bx bx-globe",
            title: this.props.t("New Meet Requests"),
            value: tempData.meet_count,
            badgeValue: "",
            color: "warning",
            desc: this.props.t("Your pending meets."),
          }
        ]
      })
    }

    const data = await getDashboardData();
    const matched_jobs = await getMatchedJobs();
    data['new_jobs'] = matched_jobs['jobs'];
    sessionStorage.setItem('dashboard_member', JSON.stringify(data));

    let user_data = JSON.parse(sessionStorage.getItem('authUser'));
    let profile_image = user_data.user.profile_image;
    let full_name = role() === "candidate" ? user_data.user.first_name + " " + user_data.user.last_name : user_data.user.fullname;
    let member_uuid = user_data.user.id ? user_data.user.id : null;

    this.setState({
      user: {
        id: member_uuid,
        name: full_name,
        image: profile_image
      },
      data,
      reports: [
        {
          icon: "bx bx-briefcase",
          title: this.props.t("Applied"),
          value: data.application_count,
          badgeValue: "",
          color: "success",
          desc: this.props.t("Your job applies."),
        },
        {
          icon: "bx bx-show",
          title: "Stellenangebote",  //this.props.t("Viewed"),
          value: data.jobs_count.all_jobs,
          badgeValue: "",
          color: "success",
          desc: "Anzahl der Stellenangebote"  //this.props.t("Your resume views."),
        },
        {
          icon: "bx bx-message",
          title: this.props.t("New Meet Requests"),
          value: data.meet_count,
          badgeValue: "",
          color: "warning",
          desc: this.props.t("Your pending meets."),
        }
      ]
    });
  }

  render(){
    return (
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>{this.props.t("Dashboard")}</title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title={this.props.t("Dashboard")} breadcrumbItem={this.props.t("Analytics")} />
              {this.state.data &&
                <CardUser reports={this.state.data}/>
              }
              <Row>
                <CardWelcome data={this.state.data}/>
                <Col xl="8">
                  <Row>
                    <MiniWidget reports={this.state.reports} />
                  </Row>
                </Col>
              </Row>
              {this.state.data && this.state.data.meet_requests.length > 0 &&
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <div className="h4 card-title">{this.props.t("Meet Requests")}</div>
                        <p className="card-title-desc">
                          {this.props.t("The companies listed at the below who wants to meet with you.")}
                        </p>
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <tbody>
                              {this.state.data.meet_requests.map((m,r) => {
                                return(
                                    <tr key={r}>
                                      <td style={{width: 100}}>
                                        <img src={m.logo ? m.logo : placeholder} height={50} width={150} style={{objectFit: 'scale-down'}}/>
                                      </td>
                                      <td style={{paddingTop: 8}}>
                                        <h5><b>{m.legal_title}</b><br/><small>{moment(m.send_date).format('DD.MM.YYYY HH:mm')}</small></h5>
                                        <button type="button" onClick={() => this.openChat(md5(m.customer_id.toString() + m.member_id.toString()), m.customer_id)} className='btn btn-primary w-xs'>
                                          <i className="mdi mdi-chat"/>
                                        </button>
                                      </td>
                                      <td style={{width: 200, textAlign: 'right'}}>
                                          <div className="btn-group btn-group-example mb-3" role="group">
                                            <button type="button" onClick={() => this.handleMeet(m.id, 1)} className={`btn ${parseInt(m.action) === 0 ? 'btn-primary' : 'btn-light'} w-xs`} disabled={parseInt(m.action) !== 0}>
                                              <i className="mdi mdi-thumb-up"/>
                                            </button>
                                            <button type="button" onClick={() => this.handleMeet(m.id, 2)} className={`btn ${parseInt(m.action) === 0 ? 'btn-danger' : 'btn-light'} w-xs`} disabled={parseInt(m.action) !== 0}>
                                              <i className="mdi mdi-thumb-down"/>
                                            </button>
                                          </div>
                                          {m.action !== "0" ?
                                              <div style={{width: '100%', position: 'relative', marginTop: -10}}>
                                                <small>
                                                <b>{m.action === "2" ? this.props.t("meet_rejected") : this.props.t("meet_accepted")}</b>.
                                                </small>
                                              </div>
                                          :
                                              <div style={{width: '100%', position: 'relative', marginTop: -10}}><small>{this.props.t("Not responded yet.")}</small></div>
                                          }
                                      </td>
                                    </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              }
              <Row>
                <Col lg="6">
                  <Card>
                    <CardBody>
                      <div className="h4 card-title">{this.props.t("Newest Jobs")}</div>
                      <p className="card-title-desc">{this.props.t("You can find the newest jobs that you can apply.")}</p>
                      <div className="table-responsive">
                        <table className="table mb-0">
                          <tbody>
                            {this.state.data && this.state.data.new_jobs && this.state.data.new_jobs.map((j, k) => {
                              return(
                                  <JobRow key={k} job={j}/>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card>
                    <CardBody>
                      <div className="h4 card-title">{this.props.t("Applied Jobs")}</div>
                      <p className="card-title-desc">{this.props.t("The jobs that you have applied before.")}</p>
                      <div className="table-responsive">
                        <table className="table mb-0">
                          <tbody>
                            {this.state.data && this.state.data.applied_jobs.map((j, k) => {
                              return(
                                  <JobRow key={k} job={j}/>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            {this.state.user &&
            <ChatFrame
                showChatbox={this.state.show_chat}
                chatbox={
                  this.state.messages.length === 0 ?
                  <div style={{border: "solid 1px #ccc", background: "#fff", width: 300, height: 400, padding: 15}}>Sie können erst mit dem Arbeitgeber chatten, wenn die Kontaktanfrage akzeptiert wurde. Die Chat-Funktion kann nur vom Arbeitgeber gestartet werden.</div>
                  :
                  <ChatBox
                      messages={this.state.messages}
                      userId={this.state.user.id}
                      onSendMessage={this.onSendMessage}
                      width={'300px'}
                      placeholder="Geben Sie hier Ihre Nachricht ein..."
                      disableInput={this.state.messages.length === 0}
                  />
                }
            />
            }
          </div>
        </React.Fragment>
    )
  }
}

MemberDashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(MemberDashboard)
