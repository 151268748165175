import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import ReactApexChart from "react-apexcharts"

function Earning(props) {
  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10
      },
    },
    xaxis: {
      labels: {
        rotate: 0
      },
      type: 'category',
      categories: props.reports ? [...props.reports.compare.map(c => c.customer !== null ? c.customer.substr(0,5) : "?")] : []
    },
    fill: {
      opacity: 1
    }
  }
  const getSeries = () => {
    if(parseInt(selectedMeasure) === 0){
      return [
        {
          name: props.t("Views"),
          data: props.reports ? [...props.reports.compare.map(c => c.sum_views)] : [],
        },
        {
          name: props.t("Clicks"),
          data: props.reports ? [...props.reports.compare.map(c => c.sum_clicks)] : [],
        },
      ]
    }

    if(parseInt(selectedMeasure) === 1){
      return [
        {
          name: props.t("Views"),
          data: props.reports ? [...props.reports.compare.map(c => c.sum_views)] : [],
        }
      ]
    }

    if(parseInt(selectedMeasure) === 2){
      return [
        {
          name: props.t("Clicks"),
          data: props.reports ? [...props.reports.compare.map(c => c.sum_clicks)] : [],
        }
      ]
    }
  }
  const onChangeMeasurement = (value) => {
    setSelectedMeasure(value);
  }
  const [selectedMeasure, setSelectedMeasure] = useState(0);
  const [series, setSeries] = useState(getSeries());
  useEffect(() => {
    setSeries(getSeries());
  }, [selectedMeasure])

  return (
    <React.Fragment>
      <Col xl="8">
        <Card>
          <CardBody>
            <div className="clearfix">
              <div className="float-end">
                <div className="input-group input-group">
                  <select
                    className="form-select form-select-sm"
                    value={selectedMeasure}
                    onChange={(e) => {
                      onChangeMeasurement(e.target.value)
                    }}>
                    <option value={0}>{props.t("All")}</option>
                    <option value={1}>{props.t("Views")}</option>
                    <option value={2}>{props.t("Clicks")}</option>
                  </select>
                  <label className="input-group-text">{props.t("Measure")}</label>
                </div>
              </div>
              <h4 className="card-title mb-4">{props.t("Customer Statistics")}</h4>
            </div>
            <Row>
              <Col lg="4">
                <div className="text-muted">
                  <div className="mb-4">
                    <p>{props.t("Advertisement statics with read and number of clicks.")}</p>
                    <h4>{new Intl.NumberFormat('de-DE').format(props.reports.compare.reduce(function (acc, obj) {return acc + parseInt(obj.sum_views)}, 0))}</h4>
                    <div>
                      <span className="badge badge-soft-success font-size-12 me-1">
                        <i className="bx bx-show"/>{" "}
                      </span>{" "}
                      {props.t("Views")}
                    </div>
                  </div>
                  <div className="mb-4">
                    <h4>{new Intl.NumberFormat('de-DE').format( props.reports.compare.reduce(function (acc, obj) {return acc + parseInt(obj.sum_clicks)}, 0)) }</h4>
                    <div>
                      <span className="badge badge-soft-success font-size-12 me-1">
                        <i className="bx bx-mouse"/>{" "}
                      </span>{" "}
                      {props.t("Clicks")}
                    </div>
                  </div>
                  <div>
                  {props.reports.top5 && props.reports.top5[0] && 
                    <Link to={`/report/${props.reports.top5[0].uuid}`} className="btn btn-primary  btn-sm">
                      Übersicht{" "}
                      <i className="mdi mdi-chevron-right ms-1"/>
                    </Link>
                  }
                  </div>
                </div>
              </Col>
              <Col lg="8">
                <div id="line-chart" dir="ltr">
                  <ReactApexChart
                    series={series}
                    options={options}
                    type="bar"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

Earning.propTypes = {
  reports: PropTypes.object,
  t: PropTypes.any
}

export default withTranslation()(Earning)
