import { all, fork } from "redux-saga/effects"
import AuthSaga from "./auth/login/saga"
import forgetPasswordSaga from "./auth/forgetpwd/saga";
import ForgetSaga from "./auth/forgetpwd/saga"
import LayoutSaga from "./layout/saga"

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(forgetPasswordSaga),
    fork(ForgetSaga),
    fork(LayoutSaga)
  ])
}
