import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { role, getStorageUser } from "../../helpers/utils";
import { Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const Navbar = props => {
  const [anzeigen, setAnzeigen] = useState(false);
  const [seiten, setSeiten] = useState(false);
  const [employer, setEmployer] = useState(false);

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  });

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      {role() === "candidate" ?
          <div className="topnav">
            <div className="container-fluid">
              <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
                <Collapse
                    isOpen={props.leftMenu}
                    className="navbar-collapse"
                    id="topnav-menu-content">
                  <ul className="navbar-nav">

                    <li className="nav-item">
                      <Link className="nav-link" to="/dashboard">
                        <i className="bx bx-pie-chart-alt-2 me-2"/>
                        {props.t("Dashboard")} {props.menuOpen}
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/jobs">
                        <i className="bx bx-briefcase me-2"/>
                        {props.t("Jobs")} {props.menuOpen}
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/resume">
                        <i className="bx bx-news me-2"/>
                        {props.t("Cv")} {props.menuOpen}
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/applied-jobs">
                        <i className="bx bx-bookmark me-2"/>
                        Meine Bewerbungen {props.menuOpen}
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/matched-anfragen">
                        <i className="bx bx-bookmark me-2"/>
                        Meine Matching-Anfragen {props.menuOpen}
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/job-agent">
                        <i className="bx bx-bell me-2"/>
                        Job Agent {props.menuOpen}
                      </Link>
                    </li>

                  </ul>
                </Collapse>
              </nav>
            </div>
          </div>
          :
          role() === "admin" ?
            <div className="topnav">
              <div className="container-fluid">
                <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
                  <Collapse
                    isOpen={props.leftMenu}
                    className="navbar-collapse"
                    id="topnav-menu-content">
                    <ul className="navbar-nav">

                      <li className="nav-item">
                        <Link className="nav-link" to="/dashboard">
                          <i className="bx bx-pie-chart-alt-2 me-2" />
                          {props.t("Dashboard")} {props.menuOpen}
                        </Link>
                      </li>

                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle arrow-none"
                          to="/#"
                          onClick={e => {
                            e.preventDefault()
                            setEmployer(!employer)
                          }}>
                          <i className="bx bx-buildings me-2" /> {props.t("Employer")}
                          <div className="arrow-down" />
                        </Link>
                        <div className={classname("dropdown-menu", { show: employer })}>
                          <div className="dropdown">
                            <Link to="/customers" className="dropdown-item">{props.t("Companies")}</Link>
                            <Link to="/contacts" className="dropdown-item">{props.t("Contact Person")}</Link>
                          </div>
                        </div>
                      </li>

                      <li className="nav-item">
                        <Link className="nav-link" to="/jobs">
                          <i className="bx bx-briefcase me-2" />
                          {props.t("Jobs")} {props.menuOpen}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link className="nav-link" to="/members">
                          <i className="bx bx-user me-2" />
                          {props.t("Members")} {props.menuOpen}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link className="nav-link" to="/job/candidates/">
                          <i className="bx bx-poll me-2" />
                          {props.t("Job Applications")} {props.menuOpen}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link className="nav-link" to="/candidates/meets">
                          <i className="bx bx-mail-send me-2" />
                          {props.t("Meet Requests")} {props.menuOpen}
                        </Link>
                      </li>

                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle arrow-none"
                          to="/#"
                          onClick={e => {
                            e.preventDefault()
                            setAnzeigen(!anzeigen)
                          }}>
                          <i className="bx bx-bookmarks me-2" /> {props.t("Job Management")}
                          <div className="arrow-down" />
                        </Link>
                        <div className={classname("dropdown-menu", { show: anzeigen })}>
                          <div className="dropdown">
                            <Link to="/management/jobs" className="dropdown-item">{props.t("Job Group")}</Link>
                            <Link to="/management/branches" className="dropdown-item">{props.t("Branches")}</Link>
                            <Link to="/management/benefits" className="dropdown-item">{props.t("Additional Services")}</Link>
                          </div>
                        </div>
                      </li>

                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle arrow-none"
                          to="/#"
                          onClick={e => {
                            e.preventDefault()
                            setSeiten(!seiten)
                          }}>
                          <i className="bx bx-edit me-2" /> {props.t("Site")}
                          <div className="arrow-down" />
                        </Link>
                        <div className={classname("dropdown-menu", { show: seiten })}>
                          <div className="dropdown">
                            <Link to="/site/page/impressum" className="dropdown-item">{props.t("Imprint")}</Link>
                            <Link to="/site/page/agb" className="dropdown-item">{props.t("Terms and Conditions")}</Link>
                            <Link to="/site/page/datenschutz" className="dropdown-item">{props.t("Privacy")}</Link>
                            <Link to="/site/page/arbeitgeber" className="dropdown-item">{props.t("Employer")}</Link>
                            <Link to="/site/page/uber-uns" className="dropdown-item">{props.t("About Us")}</Link>
                            <Link to="/site/page/hilfecenter" className="dropdown-item">{props.t("Help Center")}</Link>
                            <Link to="/site/page/faq" className="dropdown-item">{props.t("FAQ")}</Link>
                            <Link to="/site/page/bewerbung" className="dropdown-item">{props.t("Application")}</Link>
                            <Link to="/site/page/karriere-tipps" className="dropdown-item">{props.t("Career Tips")}</Link>
                            <Link to="/site/page/gehaltsrechner" className="dropdown-item">{props.t("Salary Calculator")}</Link>
                            <Link to="/site/page/recruiter" className="dropdown-item">{props.t("Werber")}</Link>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Collapse>
                </nav>
              </div>
            </div>
          :
            <div className="topnav">
              <div className="container-fluid">
                <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
                  <Collapse
                    isOpen={props.leftMenu}
                    className="navbar-collapse"
                    id="topnav-menu-content">
                    <ul className="navbar-nav">

                      <li className="nav-item">
                        <Link className="nav-link" to="/dashboard">
                          <i className="bx bx-pie-chart-alt-2 me-2" />
                          {props.t("Dashboard")} {props.menuOpen}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link className="nav-link" to={`/customers/${getStorageUser().member_uuid}`}>
                          <i className="bx bx-buildings me-2" />
                          {props.t("Company")} {props.menuOpen}
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link className="nav-link" to={`/jobs/${getStorageUser().member_uuid}`}>
                          <i className="bx bx-briefcase me-2" />
                          {props.t("Jobs")} {props.menuOpen}
                        </Link>
                      </li>

                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle arrow-none"
                          to="/#"
                          onClick={e => {
                            e.preventDefault()
                            setAnzeigen(!anzeigen)
                          }}>
                          <i className="bx bx-bookmarks me-2" /> {props.t("Candidates")}
                          <div className="arrow-down" />
                        </Link>
                        <div className={classname("dropdown-menu", { show: anzeigen })}>
                          <div className="dropdown">
                            <Link to={`/candidates/${getStorageUser().member_uuid}`} className="dropdown-item">{props.t("Job Applications")}</Link>
                            <Link to={`/candidates/search/${getStorageUser().member_uuid}`} className="dropdown-item">{props.t("Cv Search")}</Link>
                            <Link to={`/candidates/meets/${getStorageUser().member_uuid}`} className="dropdown-item">{props.t("Meet Requests")}</Link>
                          </div>
                        </div>
                      </li>

                      <li className="nav-item">
                        <Link className="nav-link" to={`/agent/settings/${getStorageUser().member_uuid}`}>
                          <i className="bx bx-user me-2" />
                          Bewerber-Agent {props.menuOpen}
                        </Link>
                      </li>

                    </ul>
                  </Collapse>
                </nav>
              </div>
            </div>
      }
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
