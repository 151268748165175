import React from "react"
import { Row, Col, Card } from "reactstrap"
import { withTranslation } from "react-i18next"
import profileImg from "../../assets/images/profile-img.png"
import PropTypes from "prop-types";

function CardWelcome(props) {
  return (
    <React.Fragment>
    <Col xl="4">
      <Card className="bg-primary bg-soft">
        <div>
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">{props.t("Hello, welcome back!")}</h5>
                <p>{props.t("Manage your account")}</p>
                <ul className="ps-3 mb-0">
                  <li className="py-1">{props.customersCount} {props.t("Customer")}</li>
                  <li className="py-1">{props.membersCount} {props.t("Members")}</li>
                </ul>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
      </Card>
    </Col>
  </React.Fragment>
  );
}

CardWelcome.propTypes = {
  customersCount: PropTypes.number,
  membersCount: PropTypes.number,
  t: PropTypes.any
}

export default withTranslation()(CardWelcome);
