import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { domain } from "../Const";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !sessionStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }else{
        const u = JSON.parse(sessionStorage.getItem("authUser"));

        if(u && u.user.role === "candidate"){
          document.cookie = "lmuuid=" + u.user.id + ";path=/;domain=" + domain;
        }
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware;
