import { call, put, takeEvery } from "redux-saga/effects"
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess } from "./actions"
import { postJwtLogin } from "../../../helpers/service_helper"
import { domain } from "../../../Const";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtLogin, {
      u: user.email,
      p: user.password,
      i: user.id
    });

    sessionStorage.setItem("authUser", JSON.stringify(response))

    if(response.user.role === "candidate"){
      document.cookie = "lmuuid=" + response.user.id + ";path=/;domain=" + domain;
    }else{
      document.cookie = 'lmuuid=; path=/; domain=' + domain + '; expires=' + new Date(0).toUTCString();
    }

    yield put(loginSuccess(response));

    document.location = "/dashboard";

  } catch (error) {

    console.log(error);

    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    document.cookie = 'lmuuid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    sessionStorage.removeItem('authUser');
    sessionStorage.removeItem('dashboard');
    sessionStorage.removeItem('dashboard_member');
    history.push("/login")

  } catch (error) {
    yield put(apiError(error))
  }
}


function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
