import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import translationDE from "./locales/de/translation.json"

// the translations
const resources = {
  de: {
    translation: translationDE,
  }
}

const language = sessionStorage.getItem("I18N_LANGUAGE");

if (!language) {
  sessionStorage.setItem("I18N_LANGUAGE", "de")
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: sessionStorage.getItem("I18N_LANGUAGE") || "de",
    fallbackLng: "de", // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
