export const POST_JWT_LOGIN = "/login"
export const POST_FORGET = "/forget"
export const GET_DASHBOARD = "/dashboard"
export const GET_DASHBOARDS = "/dashboards"
export const POST_DASHBOARD = "/dashboard"
export const GET_JOB_LIST = "/jobs"
export const GET_JOB_DETAIL = "/job"
export const DELETE_ADDRESS = "/address"
export const GET_LOCALITIES = "/localities"
export const GET_REGIONS = "/regions"
export const POST_REGIONS = "/regions"
export const GET_COUNTRIES = "/countries"
export const POST_ADDRESS = "/address"
export const PUT_JOB = "/job"
export const POST_BENEFIT = "/benefit"
export const DELETE_JOB = "/job"
export const GET_PUBLISH_JOB = "/publish"
export const GET_UNPUBLISH_JOB = "/unpublish"
export const GET_CUSTOMER_LIST = "/customers"
export const GET_OFFICES = "/offices"
export const GET_CONTACTS = "/contacts"
export const PUT_CONTACTS = "/contacts"
export const POST_CUSTOMER = "/customers"
export const GET_CUSTOMER = "/customer"
export const DELETE_CUSTOMER = "/customer"
export const DELETE_PA_ITEM = "/customer_agent"
export const ADD_PA_ITEM = "/customer_agent"
export const PUT_PA_ITEM = "/customer_agent"
export const PUT_CUSTOMER = "/customer"
export const POST_OFFICE = "/office"
export const GET_OFFICE = "/office"
export const DELETE_OFFICE = "/office"
export const GET_ROBOT = "/robot"
export const GET_JOB_CREATE = "/create"
export const GET_DOPING = "/doping"
export const GET_MANAGEMENT = "/management"
export const PUT_MANAGEMENT = "/management"
export const POST_MANAGEMENT = "/management"
export const DELETE_MANAGEMENT = "/management"
export const GET_PAGE = "/page"
export const PUT_PAGE = "/page"
export const GET_MATCHED_JOBS = "/matched_jobs"
export const POST_GEOLOCATION = "/geo_location"
export const POST_SEARCH_CITY = "/geo_search_city"
export const PUT_JOB_ALERT_PREFERENCES = "/user_preferences"
export const GET_JOB_ALERT_PREFERENCES = "/user_preferences"
export const POST_PARSE_RESUME = "/parse_resume"
export const POST_USER_RESUME = "/user_resume"
export const GET_USER_RESUME = "/user_resume"
export const GET_RESUME_SUMMARY = "/resume_summary"
export const DELETE_RESUME_ATTACHMENT = "/resume_attachment"
export const PUT_CHANGE_PASSWORD = "/change_password"
export const PUT_ACCOUNT_DEACTIVATE = "/account_deactivate"
export const DELETE_ACCOUNT = "/account"
export const GET_CANDIDATES = "/candidates"
export const GET_APPLIED_CANDIDATES = "/applied_candidates"
export const GET_CANDIDATE_DETAIL = "/candidate"
export const POST_MEET_REQUEST = "/meet"
export const POST_BULK_MEET_REQUEST = "/meets"
export const PUT_MEET_RESPONSE = "/meet"
export const DELETE_MEET_REQUEST = "/meet"
export const GET_MEET_REQUESTS = "/meets"
export const POST_SEARCH = "/search"
export const POST_FILTER = "/filter"
export const GET_MATCHED_RESUMES = "/matched_resumes"
export const GET_ME = "/me"
export const PUT_ME = "/me"
export const GET_MEMBERS = "/members"
export const GET_USERS = "/users"
export const DELETE_USER = "/users"
export const POST_USER = "/users"
export const GET_REPORT = "/report"
export const PUT_JOB_CUSTOMER = "/job_customer"
export const GET_CHAT = "/chat"
export const POST_CHAT = "/chat"
export const GET_NOTIFICATIONS = "/notifications"
export const DELETE_NOTIFICATIONS = "/notifications"