import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import moment from "moment"
import { Row, Col, Card, CardBody, Media, } from "reactstrap"
import avatar from "../../assets/images/avatar.png"
import { Link } from "react-router-dom";

function CardUser(props) {
  const [userObj, setUserObj] = useState({profileImage: null, fullname: "", email: "", last_login: "", uuid:""});

  useEffect(() => {
    let data = JSON.parse(sessionStorage.getItem('authUser'));
    if(data){
      setUserObj({
        profileImage: data.user.profile_image,
        fullname: data.user.fullname,
        email: data.user.email,
        last_login: data.user.last_login,
        uuid: data.user.member_uuid
      })
    }
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="4">
                  <Media>
                    <div className="me-3">
                      <img
                        src={userObj.profileImage === null ? avatar : userObj.profileImage }
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <Media className="align-self-center" body>
                      <div className="text-muted">
                        <p className="mb-2"><small>{moment(userObj.last_login).format("DD.MM.YYYY HH:mm")}</small></p>
                        <h5 className="mb-1">{userObj.fullname}</h5>
                        <p className="mb-0">{userObj.email}</p>
                      </div>
                    </Media>
                  </Media>
                </Col>

                <Col lg="4" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs="6">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            {props.t("Job Coin")}
                          </p>
                          <h5 className="mb-0">{props.reports.credits && props.reports.credits.job_credit}</h5>
                        </div>
                      </Col>
                      <Col xs="6">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            {props.t("Meet Coin")}
                          </p>
                          <h5 className="mb-0">{props.reports.credits && props.reports.credits.cv_credit}</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col lg="4" className="d-none d-lg-block">
                  <div className="clearfix mt-4 mt-lg-0 float-end">
                    <br/>
                    <Link to={`/account/${userObj.uuid}`}><i className="bx bxs-cog align-middle me-1" /> {props.t("Settings")}</Link>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

CardUser.propTypes = {
  reports: PropTypes.any,
  t: PropTypes.any
}

export default withTranslation()(CardUser)
